<template>
  <div>
    <!--<div class="row">
      <div class="col-12">
        <vue-dropzone :useCustomSlot=true ref="dropzone" id="dropzone" v-on:vdropzone-success="uploadProdutosdeArquivo" v-on:vdropzone-removed-file="limpaItensSelecionados()" :options="dropzoneOptions">
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">
              Arraste e solte para importar de um arquivo!
            </h3>
            <div class="dropzone-custom-subtitle">...ou clique para selecionar um arquivo do seu computador</div>
          </div>
        </vue-dropzone>
      </div>
    </div>-->
    <div class="row">
      <div class="col-6">
        <span class="clientes filters__search">
          <input type="text" v-model="filter" placeholder="Filtrar por chave ou perfil">
          <img src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwNDE2ZiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K" />
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="scrollable lista-clientes" style="height: 300px;">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th>Disponiveis</th>
                <th>Perfil</th>
                <th>Sistemas</th>
                <th>#</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in itensFiltered">
                <td>{{ item.chave.substr(0, 10) }}...</td>
                <td>{{ item.tipo_id }}</td>
                <td>
                  <span style="margin-right: 2px;" class="label primary" v-if="item.acessab2b">BD 2B</span>
                  <span style="margin-right: 2px;" class="label primary" v-if="item.acessacallcenter">BD Call</span>
                  <span style="margin-right: 2px;" class="label primary" v-if="item.acessafv">BD Vendas</span>
                  <span style="margin-right: 2px;" class="label primary" v-if="item.acessasac">SAC</span>
                  <span style="margin-right: 2px;" class="label primary" v-if="item.acessab2bapp">BD 2B (App)</span>
                </td>
                <td>
                  <button v-on:click="onSelect(item)" class="button button-success">
                    <svg x="0px" y="0px" viewBox="0 0 42 42" style="width: 12px; height: 12px; fill: rgb(255, 255, 255);"><path d="M37.059,16H26V4.941C26,2.224,23.718,0,21,0s-5,2.224-5,4.941V16H4.941C2.224,16,0,18.282,0,21s2.224,5,4.941,5H16v11.059 C16,39.776,18.282,42,21,42s5-2.224,5-4.941V26h11.059C39.776,26,42,23.718,42,21S39.776,16,37.059,16z"></path></svg>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="scrollable lista-clientes" style="height: 300px;">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th colspan="2">
                  Vinculadas
                  <small class="small-text-info text-gray">({{ itensSelecionados.length}}/{{licencas.length}})</small>
                </th>
                <th>Sistemas</th>
                <th>
                  <span class="button button-link pull-right" v-if="itensSelecionados.length" v-on:click="limpaItensSelecionados()" title="Limpar todos">Limpar todos <fa-icon icon="trash-alt" /></span>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in itensSelecionados">
                <td class="highlight">{{ item.chave.substr(0, 10) }}...</td>
                <td class="highlight">{{ item.tipo_id }}</td>
                <td class="highlight">
                  <span style="margin-right: 2px;" class="label primary" v-if="item.acessab2b">BD 2B</span>
                  <span style="margin-right: 2px;" class="label primary" v-if="item.acessacallcenter">BD Call</span>
                  <span style="margin-right: 2px;" class="label primary" v-if="item.acessafv">BD Vendas</span>
                  <span style="margin-right: 2px;" class="label primary" v-if="item.acessasac">SAC</span>
                  <span style="margin-right: 2px;" class="label primary" v-if="item.acessab2bapp">BD 2B (App)</span>
                </td>
                <td class="actions">
                  <button type="button" name="button" v-on:click="onRemove(item)" class="button button-error">
                    <fa-icon icon="trash-alt" />
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#dropzone {
  position: relative;
}
.dropzone-custom-content {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;
}

.dropzone-custom-title {
  color: #00b782;
  font-size: 1.5rem;
  margin: 0 0 0.6rem;
  background-color: initial;
}

.dropzone-custom-subtitle {
  color: #314b5f;
}
</style>

<script>

  import vue2Dropzone from "vue2-dropzone";
  import "vue2-dropzone/dist/vue2Dropzone.min.css";  
  import setup from "../../../config";
  import _some from "lodash/some";
  import _find from "lodash/find";
  import _concat from "lodash/concat";
  import _filter from "lodash/filter";
  import _difference from "lodash/difference";
  import { toMonetary, isNumeric } from "../../../helpers/utils";
  

  const dropzoneDictionary = {
    dictDefaultMessage:
      "Arraste um arquivo .xls(x) ou clique para importar licenças",
    dictFallbackMessage:
      "Seu navegador não suporta upload o recurso de arrastar e soltar",
    dictFileTooBig: "O arquivo excedeu o tamanho máximo permitido (5MB)",
    dictInvalidFileType: "Arquivo no formato inválido",
    dictResponseError: "Erro ao processar solicitação, tente novamente!",
    dictCancelUpload: "Cancelar",
    dictCancelUploadConfirmation: "Tem certeza?",
  };

  export default {
    name: "licencas",
    components: {
      vueDropzone: vue2Dropzone,
    },
    props: [
      "item",      
      "licencas",
    ],
    data() {
      return {
        filter: "",
        dropzoneOptions: {
          url: `${setup.api}/admin/importar-licencas`,
          acceptedFiles: ".xls, .xlsx",
          maxFilesize: 5,
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          paramName: "licencas",
          ...dropzoneDictionary,
        },
      };
    },
    methods: {
      uploadProdutosdeArquivo(arquivo, resposta) {
        const idsValidos = _filter(resposta.licencas, (id) => {
          return _find(this.licencas, (produto) => `${produto.id}` === `${id}`);
        });
        const newClients = _difference(idsValidos, this.item.licencas);
        this.item.licencas = _concat(this.item.licencas, newClients);
      },
      onSelect(item) {
        this.item.licencas.push(item);
      },
      onRemove(id) {
        console.table(this.item.licencas)
        this.item.licencas = _filter(this.item.licencas, (chave) => {
          console.table(id.chave)
          console.table(chave.chave)
          return id.chave !== chave.chave;
        });

        console.table(this.item.licencas)
      },
      limpaItensSelecionados() {
        //this.$refs.dropzone.removeAllFiles();
        this.item.licencas = [];
      },
    },
    computed: {
      itensFiltered() {
        if (!this.item.licencas) {
          return [];
        }

        const toFilter = this.licencas.filter((loja) => {
          return !this.item.licencas.some((chave) => `${chave.chave}` === `${loja.chave}`);
        });

        let text = this.filter;

        if (text) {
          return toFilter
            .filter((loja) => {
              text = text.toLowerCase();

              // Chave
              if (loja.chave && loja.chave.toLowerCase().match(text)) {
                return true;
              }

              // Tipo
              if (loja.tipo_id && loja.tipo_id.toLowerCase().match(text)) {
                return true;
              }
            })
            .slice(0, this.resultLimit);
        }

        return toFilter.slice(0, this.resultLimit);
      },
      itensSelecionados() {
        if (this.item.licencas && !this.item.licencas.length === 0) {
          return [];
        }
        return _filter(this.licencas, (produto) => {
          return (
            _some(this.item.licencas, (chave) => `${produto.chave}` === `${chave.chave}`) ||
            false
          );
        });
      },
    },
  };
</script>
